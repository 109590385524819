$kolor: #FCDE51;
$kolor_text: #1a1a1a;

@import "google-fonts.css";

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ececec;
  font-family: 'Muli', sans-serif !important;
}
#root{
  display: grid;
  min-height: 100%;
  a, a:hover, a:active, a:visited{
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }
  tr {
    background-color: #fff;
  }
  tr:nth-child(even) {
    background-color: #ededed;
  }
  thead tr {
    background-color: $kolor;
    color: $kolor_text;
  }
  td {
    padding: 2px 4px;
    border: 1px solid #fff;
  }
  .pull-right{ float: right; }
  .pull-left{ float: left; }
  .center{ text-align: center; }
  .left{ text-align: left; }
  .right{ text-align: right; }
  table.transparent{
    tr{
      background-color: transparent;
      td{
        border: 0 none;
        text-align: left;
      }
    }
  }
  header {
    height: 60px;
    width: 100%;
    position: relative;
    margin: 0 auto 15px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: header;
    .nav{ height: 100%; }
    button.logout{
      background-color: #9fa2b4;
      display: block;
      height: 100%;
      padding: 5px 40px;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      border: none;
      cursor: pointer;
      svg{
        fill: #fff;
        margin-right: 10px;
        vertical-align: sub;
      }
      &:hover{
        background-color: $kolor;
        color: $kolor_text;
        svg{ fill: $kolor_text; }
      }
    }
    .auth_info{
      font-size: 13px;
      font-weight: 400;
      line-height: 60px;
      color: #373f51;
      >span{
        padding-right: 60px;
        padding-left: 22px;
      }
      .auth_info_name{
        background: url(../assets/user.png) no-repeat center left;
      }
      .auth_info_rma_zone{
        background: url(../assets/packing_fac.png) no-repeat center left;
      }
    }
    .nav{
      align-items: flex-end;
    }
    .logo{
      width: 170px;
      height: 57px;
      margin-left: 18px;
      background: url(../assets/logotype.png) no-repeat center left / contain #ffffff;
    }
  }
  article {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    grid-template-areas:
        "header"
        "main"
        "footer";
  }
  main{
    font-size: 16px;
    padding: 10px 15px;
    grid-area: main;
    h1{
      font-size: 18px;
      font-weight: 700;
    }
    h2{
      font-size: 24px;
      font-weight: 300;
      line-height: 34px;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 20px;
      .subheader{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    h3{
      text-align: center;
      margin-bottom: 25px;
    }
    h4{
      color: #373f51;
      font-size: 24px;
      font-weight: 300;
    }
    input{
      border-radius: 4px;
      border: solid 1px #d2d2d9;
    }
    table.tab{
      width: 100%;
      border-bottom: 2px solid #888;
      th{
        padding: 2px 8px;
        font-weight: normal;
      }
      td{
        padding: 2px 8px;
        border: none;
      }
    }
    button:not(.react-datepicker__navigation):not(.filepond--file-action-button), .button, input[type="submit"] {
      background-color: $kolor;
      color: $kolor_text;
      cursor: pointer;
      border: 0 none;
      border-radius: 0.25rem;
      line-height: 1;
      padding: 5px 20px;
      min-width: 260px;
      height: 46px;
      font-size: 15px;
      font-weight: 800;
      &.secondary{
        background-color: #fff;
        border: 1px solid #666;
        color: #000;
      }
      &[disabled]{
        background-color: #ddd;
        color: #aaa;
      }
      &.btn-normal{
        font-size: 18px;
      }
      &.btn-sm{
        font-size: 16px;
        padding: 0.6em 1.2em;
      }
    }
    .center_wrapper{
      display: grid;
      height: 100%;
      justify-content: center;
      align-content: center;
    }
    .buttons_wrap{
      margin-bottom: 30px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      button + button{
        margin-left: 30px;
      }
    }
    .ramka{
      background-color: #fff;
      padding: 20px 35px;
      margin-bottom: 40px;
      margin-top: 10px;
      border-radius: 6px;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
      >h4{
        padding: 0 15px 8px;
      }
      label, .label{
        margin-bottom: 0;
        color: #878a99;
        font-size: 16px;
        font-weight: 400;
        padding-right: 3px;
      }
      .buttons_wrap{
        margin-bottom: 20px;
      }
    }
    .searchForm{
      h4 {
        border-bottom: 0 none;
        padding-bottom: 0;
      }
      >div{
        display: flex;
        margin: 30px 0 15px;
      }
      input[type="text"]{
        height: 36px;
        width: 100%;
        padding: 0 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      input[type="submit"]{
        height: 36px;
        vertical-align: bottom;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .react-autosuggest__container {
      display: inline-block;
      width: 100%;
      position: relative;
      margin: 50px 0 20px;
      .react-autosuggest__suggestions-container {
        position: absolute;
        width: 100%;
        background-color: #fff;
        top: 100%;
        border: 1px solid #A9A9A9;
        opacity: 0;
        ul.react-autosuggest__suggestions-list {
          list-style: none;
          text-align: left;
          margin: 5px 0;
          padding-left: 0;
          li{
            padding-left: 0.8em;
            cursor: pointer;
            &:hover{
              background-color: #E0E0E0;
            }
          }
        }
      }
      .react-autosuggest__suggestions-container--open{
        opacity: 1;
      }
    }
    .searchBox{
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding: 0.8em;
      padding-left: 3.5em;
      width: 100%;
      background: url('../assets/barcode.png') 20px center no-repeat #fff;
    }
    .breadcrumb{
      padding: 0;
      display: block;
      margin-bottom: 1.5rem;
    }
    .field {
      position: relative;
      margin-top: 20px;
      label {
        font-weight: 700;
        display: block;
        position: absolute;
        top: 14px;
        left: 15px;
        color: #9fa2b4;
        background-color: transparent;
        cursor: text;
        font-size: 14px;
        transition: all .15s ease-in-out 0s;
      }
      input, select{
        height: 48px;
        font-size: 15px;
        line-height: 24px;
        transition: all .15s ease-in-out 0s;
        width: 100%;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        font-weight: 600;
        &:not(:placeholder-shown), &:focus {
          background-color: #FFFFFF;
          border-color: #9fa2b4;
          outline: 0;
          box-shadow: none;
          + label{
            top: -9px;
            font-size: 12px;
            background-color: #fff;
            color: #878a99;
            cursor: pointer;
            padding: 0 5px;
            border-radius: 5px;
            margin-left: -5px;
            font-weight: 400;
          }
        }
        &:focus{
          border-width: 2px;
          border-color: #9fa2b4;
          box-shadow: none;
          + label{
            font-weight: 700;
          }
        }
      }
      input:-internal-autofill-selected{
          background-color: #fff;
      }
    }
    .login_form{
      min-width: 350px;
      border-radius: 6px;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
      padding: 20px 45px 25px;
      form{
        position: relative;
        h2{
          font-size: 24px;
          color: #666666;
          margin: 0 0 30px;
          padding-top: 15px;
          text-align: center;
          font-weight: 300;
          letter-spacing: -0.5px;
          padding-bottom: 1.5rem;
          margin-bottom: 2rem;
          border-bottom: 1px solid #dee2e6;
        }
      }
      input[type="submit"]{
        display: block;
        width: 100%;
        margin-top: 40px;
      }
      .login-failed {
        color: #e11;
        font-weight: 600;
        text-align: center;
        padding: 1rem 0 0;
        font-size: 14px;
      }
    }

    .elem-list{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 30px;
      .elem-tile{
        color: #151408;
        text-decoration: none;
        font-size: 14px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #fff;
        padding: 15px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        font-weight: 400;
        &:hover{
          border-color: $kolor;
        }
        &.inactive{
          background-color: #f18b8b;
          color: #ffffff;
        }
        &[data-important="1"]{
          background-color: #ff5a5a !important;
          color: #fff !important;
        }
        h5{
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        h5{
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .infoRow {
          display: flex;
          justify-content: space-between;
        }
        label{
          color: #878a99;
          font-size: 14px;
          font-weight: 400;
          img{
            margin-right: 8px;
            vertical-align: unset;
          }
        }
        .elem-footer{
          font-size: 18px;
          padding: 2px 15px;
          background-color: #808080;
          color: #fff;
          overflow: hidden;
          margin: 10px -15px 0;
        }
      }
    }
    .elem-view{
      background-color: #fff;
      padding: 15px 20px;
      margin: 30px 0;
      font-size: 22px;
      label{
        font-size: 22px;
        font-weight: normal;
      }
    }
    .product_suggestions {
      .product_suggestions_columns{
        display: flex;
        justify-content: space-evenly;
        .sug_col{
          width: 100%;
        }
        .sug_col1 .sug_col_tags{
          border-right: 1px solid #ccc;
          height: calc(100% - 40px);
        }
        .sug_col_tags {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          grid-auto-flow: dense;
        }
        h5{
          margin-bottom: 1rem;
        }
      }
      span {
          height: fit-content;
          min-height: 37px;
          font-size: 18px;
          margin: 3px 8px;
          padding: 0.3em 0.8em;
          display: inline-block;
          background-color: #e0e0e0;
          cursor: pointer;
          border-radius: 3px;
          &.long{
            grid-column-end: span 3;
          }
      }
    }
    .boxy{
      border-collapse: separate;
      width: calc(100% + 35px);
      border-spacing: 20px;
      margin: 0 -20px;
      tr{ background-color: transparent; }
      td{
        border-radius: 6px;
        box-shadow: 0px 3px 4px rgba(76,67,25,0.1);
        text-align: center;
        vertical-align: middle;
        padding: 0;
        background-color: #fff;
        font-size: 36px;
        font-weight: 700;
        &.done{
          background-color: #04bf62;
          border-color: #04bf62;
          position: relative;
          &:before{
            content: "";
            width: 36px;
            height: 36px;
            display: block;
            border-radius: 50%;
            background: url(../assets/icons/green-check.png) center center no-repeat #ececec;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:not(.done){
          cursor: pointer;
        }
      }
    }
    .box_info .boxy td:not(.done){ cursor: default; }
    .f_group{
      display: grid;
      grid-template-columns: minmax(max-content, 45%) minmax(max-content, 1fr);
      grid-template-areas: "labels data";
      padding: 14px 0;
      border-top: 1px solid #e8e8e8;
      line-height: 17px;
      &.full_width{
        grid-template-columns: 1fr !important;
        grid-template-rows: max-content minmax(max-content, 1fr);
        grid-template-areas: "labels" "data";
      }
      label{
        grid-area: labels;
        padding-left: 32px;
        position: relative;
        &:before{
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-repeat: no-repeat;
          background-position: left 0;
          opacity: 0.4;
        }
      }
      >div{
        grid-area: data;
        max-width: 22vw;
      }
      &.notes label:before{ background-image: url(../assets/icons/notes.png); }
      &.carrier label:before{ background-image: url(../assets/icons/carrier.png); }
      &.date_order label:before{ background-image: url(../assets/icons/date.png); }
      &.amount_total label:before{ background-image: url(../assets/icons/amount.png); }
      &.delivery label:before{ background-image: url(../assets/icons/location.png); }
      &.invoice label:before{ background-image: url(../assets/icons/invoice.png); }
      &.allegro_account label:before{ background-image: url(../assets/icons/allegro.png); }
      &.warehouseman label:before{ background-image: url(../assets/icons/person.png); }
      &.stock_location label:before{ background-image: url(../assets/icons/carrier.png); }
      &.qty label:before{ background-image: url(../assets/icons/qty.png); }
      &.price label:before{ background-image: url(../assets/icons/price.png); }
      &.request label:before{ background-image: url(../assets/icons/person.png); }

      &.relative {
        position: relative;
      }
    }
    .rma{
      h4{
        font-size: 21px;
        font-weight: 800;
      }
      .rma_header{
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 15px;
        button, input[type="submit"]{
          padding: 0.8em 1em;
        }
      }
      .rma_order{
        padding-left: 30px;
        padding-right: 30px;
        h4{
          padding-left: 40px;
          position: relative;
          &:before{
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: url(../assets/icons/order.png) no-repeat left top transparent;
              opacity: 0.4;
          }
        }
        .order_data{
          overflow-y: auto;
          .notes > div{
            color: #ec2d2d;
            font-weight: bold;
          }
          textarea {
            width: 100%;
          }
        }
      }
      .rma_products{
        overflow-y: auto;
        .ramka.produkt{
          padding: 0;
          overflow: hidden;
          margin-bottom: 25px;
          position: relative;
          > div {
            display: flex;
            position: relative;
            justify-content: space-between;
            &.bundle_title {
              margin: 0 0 25px;
            }
          }
          .lightbox-wrapper{
            width: 240px;
            height: 180px;
            position: relative;
            button.img-open{
              padding: 15px 5px;
              border: 0;
              cursor: zoom-in;
              width: 100%;
              height: 100%;
              min-width: 0;
              background-color: transparent !important;
              >img{
                width: 100%;
                height: auto;
                max-height: 100%;
                object-fit: contain;
              }
            }
            .lightbox{
              background: rgba(0, 0, 0, 0.6);
              height: 100%;
              border-radius: 0;
              img{
                object-fit: contain;
              }
            }
            .lightbox-visible{
              transition: opacity 200ms linear;
            }
            .img-close {
              cursor: zoom-out;
            }
          }
          .productVariantValue, .defectValue {
            display: inline-block;
            border: 2px solid #d3d3d3;
            line-height: 30px;
            cursor: pointer;
            margin-bottom: 5px;
            background-color: #fff;
            padding: 2px 8px;
            margin-right: 10px;
            &.selected {
              background-color: $kolor;
              color:  $kolor_text;
              border-color: $kolor;
            }
          }
          .default_code{
            color: #959ca2;
            font-size: 16px;
            font-weight: 400;
          }
          .product_details {
            flex-grow: 1;
            padding: 20px 25px;
            border-left: 1px solid #e6e6e6;
            .f_group{
              grid-template-columns: minmax(max-content, 190px) minmax(max-content, 1fr);
              border-top: 0 none;
              padding: 4px 0;
            }
            textarea {
              width: 90%;
              margin: 10px 0;
            }
          }
          &.grey{
            .product_details, .lightbox-wrapper .img-open{
              opacity: 0.4;
            }
            .checkbox.checked{
              background: #666;
            }
            h4{
              background-color: #d3d3d3;
            }
            .productVariantValue, .checkbox{
              pointer-events: none;
            }
          }
          &.red {
            background-color: #ea4747;
            label, h4, span{
              color: #fff;
            }
            h4{ background-color: #545454; }
            .product_checkbox, .product_checkbox .checkbox.checked{
              background-color: #ea4747;
              .checkbox-inner:before, .checkbox-inner:after{
                background-color: #fff;
              }
            }
          }
          .bundleWrapper{
            margin: 0 -15px -15px;
            .ramka.bundleItemList {
              clear: both;
              padding: 0px;
              width: 100%;
              margin: 14px 0px 0;
              background-color: rgba(0, 0, 0, 0.02);
              border-top: 1px solid rgba(0, 0, 0, 0.04);
              tr{
                background-color: #fff;
              }
            }
            .bundleItemList th,
            .bundleItemList td {
              text-align: left;
              padding: 20px 5px 7px;
              border: none;
              border-bottom: 1px dashed #000;
            }
            .bundleItemList td:first-child {
              border-left: 2px solid #000;
            }
            .bundleItemList td:nth-child(2) {
              text-align: right;
              white-space: nowrap;
              padding-right: 25px;
            }
            .bundleItemList td:last-child {
              padding: 0;
              padding-top: 3px;
              border-bottom: none;
              .checkbox{
                position: relative;
                &.clickable{ cursor: pointer; }
                width: 42px;
                height: 42px;
                border-radius: 6px;
                border: 2px solid #cdcdcd;
                .check {
                  opacity: 0;
                }
              }
              .checkbox.checked .check {
                opacity: 1;
              }
            }
            .bundleItemList {
              .checkbox{
                position: relative;
                &.clickable{ cursor: pointer; }
                width: 42px;
                height: 42px;
                border-radius: 6px;
                border: 2px solid #cdcdcd;
                .check {
                  opacity: 0;
                }
              }
              .checkbox.checked .check {
                opacity: 1;
              }
            }
            .bundleItemList th {
              background-color: $kolor;
              color:  $kolor_text;
              font-weight: 300;
            }

            &.listView {
              margin: 0;
              max-width: 50%;
              width: 100%;
              padding-right: 100px;
              .ramka.bundleItemList{
                margin: 0;
                td{
                  padding: 1px 3px;
                }
              }
            }
          }
          &.expanded > div + div{
            border-top: 1px solid #e6e6e6;
            margin-top: 20px;
            padding-top: 20px;
          }
        }
      }
      .product_checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        background-color: #fafafa;
        border-left: 1px solid #e6e6e6;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        .checkbox{
          position: relative;
          &.clickable{ cursor: pointer; }
          width: 42px;
          height: 42px;
          border-radius: 6px;
          border: 2px solid #cdcdcd;
          display: flex;
          align-items: center;
          justify-content: center;
          .check {
            opacity: 0;
          }
        }
        .checkbox.checked{
          background-color: #04bf62;
          border-color: #04bf62;
          .check {
            opacity: 1;
          }
        }
      }
      .product_decision {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        label{
          position: relative;
          display: block;
          margin: 10px;
          cursor: pointer;
          .check{
            width: 112px;
            height: 112px;
            border-radius: 50%;
            background-color: #9FA2B4;
            background-position: center center;
            background-repeat: no-repeat;
            transition: background-color 0.2s ;
          }
          &:first-child{
            .check{
              background-image: url(../assets/icons/check.png);
            }
            input:checked ~ .check{
              background-color: #04BF62;
            }
          }
          &:nth-child(2){
            .check{
              background-image: url(../assets/icons/cross.png);
            }
            input:checked ~ .check{
              background-color: #e82d2d;
            }
          }
          &:nth-child(3){
            .check{
              position: relative;
              &:after{
                content: "";
                display: block;
                width: 40px;
                height: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                border-radius: 2px;
                transform: translate(-50%, -50%);
                background-color: #fff;
              }
            }
            input:checked ~ .check{
              background-color: #f7980a;
            }
          }
        }
        input[type="radio"] {
          opacity: 0;
          width: 0;
          height: 0;
          position: absolute;
        }
      }
      .product_details .actions{
          display: flex;
          flex-direction: column;
          > div{
            display: none;
            flex-direction: column;
          }
          &[data-decision="0"] > .actions_0,
          &[data-decision="1"] > .actions_1,
          &[data-decision="2"] > .actions_2{
            display: flex;
          }
          label{
            position: relative;
            display: block;
            width: 100%;
            margin-top: 20px;
            cursor: pointer;
            .check{
              width: 100%;
              height: auto;
              line-height: 25px;
              background-color: #fff;
              border: 1px solid #CBCDD6;
              border-radius: 6px;
              padding: 5px 10px 5px 30px;
              font-size: 15px;
              font-weight: 800;
              color: #000;
              transition: all 0.2s;
            }
            .check-icon{
              position: absolute;
              left: -12px;
              top: 50%;
              transform: translateY(-50%);
              text-align: center;
              width: 24px;
              height: 24px;
              line-height: 21px;
              background-color: #fff;
              border-radius: 50%;
              opacity: 0;
              transition: opacity 0.2s linear;
              fill: $kolor;
            }
            input:checked ~ .check{
              border-color: $kolor;
              background-color: $kolor;
              color: $kolor_text;
              box-shadow: 0px 2px 4px rgba(191,168,61,0.3);
              .check-icon{ opacity: 1; }
            }
          }
          input[type="radio"] {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
          }
      }
      button.expander{
        width: 240px;
        min-width: 240px !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 20px;
        background-color: #fff;
        color: #878a99;
        position: absolute;
        bottom: 0px;
        left: 0;
        border-top: 1px solid #e6e6e6;
        height: 35px;
        &:hover{ background-color: #f3f3f3; }
        &:focus{ outline: none; }
      }
    }
    .rma_footer{
      margin-top: 50px;
    }
    div.done {
      text-align: center;
      padding: 15px 35px;
      margin-bottom: 0;
      .summary {
        text-align: left;
        max-width: 1100px;
        display: inline-block;
        padding: 0 20px;
        margin-top:10px;
        h4{
          text-align: center;
          margin-bottom: 1.5rem;
          margin-top: 0.5rem;
        }
        label {
          width: 200px;
        }
        .shippinglogo > img{
          max-height: 36px;
        }
      }
      button.print_invoice:before, button.print_label:before{
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
      }
      button.print_invoice:before{
        background: url(../assets/icons/print.png) center center no-repeat transparent;
      }
      button.print_label:before{
        background: url(../assets/icons/badge.png) center center no-repeat transparent;
      }
      .buttons_wrap{ justify-content: center; }
    }
  }
  footer{
    font-weight: 400;
    font-size: 0.8rem;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $kolor_text;
    background-color: $kolor;
    padding: 0 30px;
    grid-area: footer;
    p{
      margin: 0;
    }
    select.lang{
      background-color: transparent;
      border: none;
      font-weight: 300;
      padding-right: 15px;
      margin-right: -15px;
      background: transparent;
      -webkit-appearance: none;
      option{ color: #000; }
    }
    .selectArr, .worldIcon{
      fill: $kolor_text;
      pointer-events: none;
    }
    .worldIcon{
      margin-right: 8px;
      vertical-align: sub;
      opacity: 0.7;
    }
    .opacity-3{ opacity: 0.4; }
  }
  article[data-view="Order"]{
    header{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;
    }
    main{
      padding-top: 60px;
    }
    .rma_header{
      position: fixed;
      width: 100%;
      left: 0;
      padding: 15px;
      z-index: 4;
      background-color: #ececec;
      background: linear-gradient(180deg, rgba(236,236,236,1) 80%, rgba(255,255,255,0) 100%);
    }
    .rma_content{
      padding-top: 65px;
    }
  }

  .bundleWrapper {
    table {
        width: 100%;
        tr {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px dashed #000;
            background-color: #fff!important;
        }
    }
  }


  button.highlighted {
    background-color: #f00 !important;
    color: #fff !important;
    border: 2px solid #000 !important;
  }

  .corrected-price {
    &__wrapper {
      display: flex !important;
    }
  }

  /* ================ Spinner =================*/
  @import "spinner.css";
  /* ================ Spinner =================*/
}
/* ================ Popup =================*/
@import "popup.scss";
/* ================ Popup =================*/
/* ============== Responsive ==============*/
@media (max-width: 1024px) {
  #root main .product_details .f_group{
    grid-template-areas: "labels" "data";
    grid-template-columns: 1fr !important;
    > div{ padding-left: 32px; padding-top: 5px;}
  }
}
/* ============== Responsive ==============*/
